import axios from '@/plugins/axios'
import store from '@/store'

export default {
  profile() {
    return axios.get('/admin/profile')
  },

  csrf() {
    return axios.get('public/csrf-cookie')
  },

  login (payload) {
    return axios.post('/admin/login', payload)
  },

  logout () {
    return axios.post('/admin/logout')
  },
  forgotPassword(email) {
    return axios.post('/admin/password/email', email)
  },
  resetPassword(data) {
    return axios.post('/admin/password/reset', data)
  },
  verifyEmail(id, hash, query) {
    return axios.get(`/admin/admin-email/verify/${id}/${hash}?${query}`)
  },
  resendEmailVerify() {
    return axios.post('/admin/admin-email/verification-notification')
  }
}
