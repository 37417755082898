import Api from '@/api'
import { intersection } from 'lodash'
import { getCookie } from '../../plugins/cookie'
// state
export const state = {
  user: null,
  checked: false,
}

// getters
export const getters = {
  user: state => state.user,
  check: state => state.user !== null,
  verified: state => state.user && state.user.email_verified_at !== null,
  hasRole: (state) => role => {
    if (!state.user?.active) return false
    let isSuper = state.user.roles.includes('*')
    let roles = Array.isArray(role) ? role : [role]
    let isAllowed = intersection(state.user.roles, roles).length > 0
    return isSuper || isAllowed
  }
}

// mutations
export const mutations = {
  SET_USER (state, { user }) {
    state.user = user
  },
}

// actions
export const actions = {
  async fetchUser({ commit, state }) {
    if (state.user) return

    const env = process.env.VUE_APP_ENV === 'production' ? '' : `_${process.env.VUE_APP_ENV}`
    const uid = getCookie(`admin_read${env}`)
    if (uid) {
      const response = await Api.auth.profile()
      commit('SET_USER', { user: response.data })
    } else if (!state.checked) {
      await Api.auth.csrf()
      state.checked = true
      await this.dispatch('auth/fetchUser')
    }
  },

  updateUser ({ commit }, payload) {
    commit('SET_USER', { user: payload })
  },

  async logout ({ commit }) {
    await Api.auth.logout()
    commit('SET_USER', { user: null })
  },

  clearUser ({ commit }) {
    commit('SET_USER', { user: null })
  }
}
