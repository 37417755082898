export const isBrowser = function () {
  return typeof document === 'object' && typeof document.cookie === 'string'
}

export const getCookie = (key) => {
  let cookie = ''
  if (isBrowser()) {
    const cookies = document.cookie
    const value = decodeURIComponent(cookies.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(key) + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || ''
    cookie = value
  }

  return cookie
}
